<template>
    <v-dialog persistent v-model="showDialog">
        <v-card dark color="#1F7087" class="mx-auto" max-width="400">
                <v-card-title>
                    Api Token 
                    <v-spacer></v-spacer>
                    <v-btn v-if="!tokenGenerated" icon @click="showDialog = false" ><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                <v-text-field label="Nombre" placeholder="nombre del token" v-model="name">
                </v-text-field>
                <div class="d-flex mb-2">
                    <v-text-field
                        :disabled="!tokenGenerated"
                        style="shrink"
                        dense
                        v-model="tokenGenerated"
                    >
                    </v-text-field>
                    <button type="button"
                    v-clipboard:copy="tokenGenerated"> <v-icon>mdi-content-copy</v-icon> Copiar!</button>
                </div>
                    
                <p v-if="tokenGenerated">Guarda este token en un lugar seguro porque no podrá ser revelado nuevamente</p>
                
                </v-card-text>
            <v-card-actions>
                <v-btn v-if="tokenGenerated" dark color="success" @click="exitDialog">
                    Ok, ya guardé el token.
                </v-btn>
                <v-btn v-if="!tokenGenerated" :disabled="!name" dark color="primary" @click="sendForm">
                    Generar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            tokenGenerated: undefined,
            showToken: true,
            name: '',
            contentForCopy: '',
        }
    },
    created(){
        this.$parent.$on('open-dialog-create-v2-token', () => {
            this.tokenGenerated = undefined;
            this.name = '';
            this.showDialog = true;
        });
    },
    methods:{
        copyToClipboard(content)
        {
            console.log(this.tokenGenerated);
            console.log(content);
            this.contentForCopy = String(content);
            this.$copyText(this.contentForCopy);
        },
        exitDialog(){
            this.$emit('success');
            this.showDialog = false;
        },
        sendForm(){
            var vm = this;
            this.axios({
                url: 'my-tokens',
                method: 'POST',
                data:{
                    name: this.name
                }
            }).then( response => {
                vm.tokenGenerated = response.data;
            }).catch( error => {

            });
        }
    }
}
</script>