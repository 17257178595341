<template>
    <div>
        <v-app>
            <v-container fluid>
                <v-tabs v-model="tab">
                    <v-tab>API Tokens Activos</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card flat>
                            <v-card-title>
                                <v-btn class @click="openDialogCreateV2Token" text  color="green">
                                    Generar token 
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn class @click="fetchData" text icon color="blue">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text>
                                <v-data-table :items="tableData" :headers="headers">
                                    <template v-slot:item.token="{item}">
                                        <td class="d-flex" >
                                            <!--<v-text-field
                                                style="shrink"
                                                dense
                                                :append-icon="item.showToken ? 'mdi-eye' : 'mdi-eye-off'"
                                                :append-outer-icon="'mdi-content-copy'"
                                                :type="item.showToken ? 'text' : 'password'"
                                                :value="item.token"
                                                class="input-group--focused"
                                                @click:append="item.showToken = !item.showToken"
                                                @click:append-outer="copyToClipboard(item.token)"
                                            >
                                            </v-text-field>-->
                                            {{item.token}}
                                        </td>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-container>
        </v-app>
        <dialog-create-v2-token @success="fetchData"></dialog-create-v2-token>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogCreateV2Token from "@/views/oms/Configuraciones/DialogCreateV2Token.vue";
export default {
    components:{
        DialogCreateV2Token
    },
    data(){
        return {
            tab: null,
            tableData: [],
            headers: [
                {text: "Nombre", align: "left", value: "name"},
                {text: "Token", align: "left", value: "token"},
                {text: "Fecha creación", align: "left", value: "created_at"},
                /*{text: "Fecha expiración", align: "left", value: "expires_at"},*/
            ],
        }
    },
    created(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Mis tokens", route: "/my-tokens" },
        ]);
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        copyToClipboard(content)
        {
            this.contentForCopy = String(content);
            this.$copyText(this.contentForCopy);
        },
        fetchData(){
            var vm = this;
            this.axios({
                url: 'my-tokens',
                method: 'GET'
            }).then( response => {
                vm.tableData = response.data.map(e => ({...e, showToken: false}))
            }).catch( error => {

            });
        },
        openDialogCreateV2Token(){
            this.$emit('open-dialog-create-v2-token');
        }
    }
}
</script>